.wrapper-class {
    border-color:black
}


.editor-class {
    border: 1px solid rgb(209, 213, 219);
    padding: 1rem;
    min-height: 24rem;
}

.toolbar-class {
    border: 1px solid rgb(209, 213, 219) !important; 
}
