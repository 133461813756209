.pagination { 
    display: 
    flex; justify-content:
    center; margin-top: 15px; 
} 

ul { list-style: none; padding: 0; } 

ul.pagination li { 
    color: 'black';
    display: inline-block; 
    width: 40px; 
    height: 40px; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
} 


ul.pagination li a { text-decoration: none;  font-size: 1.2rem; font-weight: 500;} 

ul.pagination li.active a {  font-weight: bold;} 

ul.pagination li.active { border-bottom: 4px solid #2A3F54;} 

/* ul.pagination li a:hover, ul.pagination li a.active {  }  */

.page-selection { width: 48px; height: 30px;}

